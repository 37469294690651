/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.screenShareHeader {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.25rem 0.5rem;
  flex-direction: column;
}

.stopButton {
  color: var(--color_alabaster);
  background: var(--color_thunderbird);
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  user-select: none;
  cursor: pointer;
  width: 100%;
  transition: opacity 0.15s;
}

.stopButton:hover {
  opacity: 0.8;
}

.description {
  margin-top: 0.25rem;
  color: var(--color_silver_chalice);
}
