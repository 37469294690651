/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.chatInput {
  width: 100%;
  height: 100%;
  padding: 0.75rem;
}

.form {
  display: flex;
  background-color: var(--color_tundora);
  border-radius: 0.25rem;
}

.input {
  flex: 1 1 auto;
  color: var(--color_alabaster);
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 0.75rem 0.5rem;
}

.input::placeholder {
  color: var(--color_silver_chalice);
}

.raiseHandButton {
  flex: 0 0 auto;
  font-size: 1.5rem;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  margin-top: 0.25rem;
}

.raiseHandButton span {
  transition: all 0.05s;
  filter: grayscale(100%);
}

.raiseHandButton span:hover {
  filter: grayscale(0);
}

.raiseHandButton.raised span {
  filter: grayscale(0) !important;
}
