/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--color_mine_shaft_light);
}

.messages {
  flex: 1 1 auto;
  overflow-y: auto;
  padding-top: 0.5rem;
}

.messageWrapper {
  padding: 0.5rem 1.25rem;
}

.senderName {
  display: inline;
  font-weight: 700;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.date {
  display: inline;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: var(--color_silver_chalice);
}

.message {
  color: var(--color_alto);
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.messageWrapper.raiseHand .message {
  font-size: 2rem;
}

.chatInput {
  flex: 0 0 auto;
}
