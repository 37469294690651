/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.remoteVideo {
  display: none;
  position: relative;
  background: transparent;
  overflow: hidden;
}

.remoteVideo.roomMode {
  border-radius: 0.25rem;
}

.remoteVideo.screenShareMode {
  border-radius: 0.25rem;
}

.remoteVideo.enabled {
  display: block !important;
}

.video {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nameplate {
  z-index: 1;
  align-items: center;
  position: absolute;
  max-width: 95%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--color_black_medium_opacity);
  backdrop-filter: blur(0.5rem);
}

.remoteVideo.roomMode .nameplate {
  display: flex;
}

.remoteVideo.screenShareMode .nameplate {
  display: none;
}

.remoteVideo.medium .nameplate {
  padding: 0.25rem 0.3rem 0.25rem 0.5rem;
  bottom: 0.25rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
}

.remoteVideo.large .nameplate {
  padding: 0.5rem 0.75em 0.5em 1rem;
  bottom: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.remoteVideo.small .nameplate {
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled .nameplate {
  display: none;
}

.remoteVideo.screenShareMode.large .nameplate {
  display: flex !important;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled.large .nameplate {
  display: flex !important;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.name {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
}

.muted {
  flex: 0 0 1.25rem;
  width: 1.25rem;
  text-align: center;
}

.remoteVideo.small .name + .muted {
  margin-left: 0.25rem;
}

.remoteVideo.medium .name + .muted {
  margin-left: 0.25rem;
}

.remoteVideo.large .name + .muted {
  margin-left: 0.5rem;
}

.raisedHand {
  z-index: 1;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  font-size: 2rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}
