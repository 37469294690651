/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.classroom {
  display: flex;
  background: var(--color_mine_shaft_light);
  height: 100%;
  align-items: center;
  justify-content: center;
}

.classroom.isModeTransitioning::after {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color_mine_shaft_light);
  z-index: 10;
}

.left {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentVideoWrapper {
  display: none;
  flex: 1 1 auto;
  overflow-y: hidden;
}

.classroom.isContentShareEnabled .contentVideoWrapper {
  display: block;
}

.classroom.screenShareMode .contentVideoWrapper {
  display: none !important;
}

.remoteVideoGroupWrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.classroom.roomMode.isContentShareEnabled .remoteVideoGroupWrapper {
  flex: 0 0 auto;
}

.localVideoWrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 0 0 var(--local_video_container_height);
}

.localVideo {
  position: absolute;
  right: 0.25rem;
}

.classroom.screenShareMode .localVideo {
  right: auto;
  width: 100%;
  height: 100%;
  padding: 0.25rem;
}

.classroom.screenShareMode .controls {
  z-index: 1;
}

.right {
  display: flex;
  flex-direction: column;
  flex: 0 0 var(--right_panel_width);
  background: var(--color_mine_shaft_medium);
  height: 100%;
  overflow: hidden;
}

.classroom.screenShareMode .right {
  display: none;
}

.titleWrapper {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--color_mine_shaft_light);
}

.title {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.label {
  font-size: 0.8rem;
  color: var(--color_silver_chalice);
}

.deviceSwitcher {
  flex: 0 1 auto;
}

.roster {
  flex: 1 1 auto;
  overflow-y: scroll;
  height: 50%;
}

.chat {
  flex: 1 1 auto;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.modal {
  outline: none;
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
}
