/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~rc-tooltip/assets/bootstrap.css';
@import '~react-dropdown/style.css';

:root {
  --local_video_container_height: 150px;
  --right_panel_width: 320px;
  --screen_picker_width: 800px;
  --screen_picker_height: 600px;
  --color_alabaster: #fafafa;
  --color_mercury: #e9e9e9;
  --color_alto: #dedede;
  --color_silver_chalice: #9e9e9e;
  --color_tundora: #454545;
  --color_mine_shaft_dark: #333;
  --color_mine_shaft_medium: #2d2d2d;
  --color_mine_shaft_light: #252525;
  --color_cod_gray_medium: #111;
  --color_cod_gray_light: #1b1b1b;
  --color_black: #000;
  --color_thunderbird: #d62f12;
  --color_black_medium_opacity: rgba(0, 0, 0, 0.6);
  --color_black_low_opacity: rgba(0, 0, 0, 0.25);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  color: var(--color_alabaster);
  background-color: var(--color_mine_shaft_light);
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  line-height: 1.45;
}

#root {
  height: 100%;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
